import React, { useEffect, useMemo, useReducer, useState } from "react";
import useQuillEditor from "../../features/hooks/useQuillEditor";
import { uploadImage } from "../../utils/uploadImage";
import ReactQuill from "react-quill";
import {
  amendPoliciesReducer,
  getPolicyData,
  initialState,
  MetaPageType,
  setContent,
  setPoliciesMetaData,
} from "../../features/reducers/Policies/reducer";
import {
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useGetPolicyQuery,
  useUpdatePolicyMutation,
} from "../../features/api/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

function AmendPoliciesPage({
  isUpdatePolicyPage,
}: {
  isUpdatePolicyPage?: boolean;
}) {
  const navigate = useNavigate();
  const params = useParams();
  const [page, setPage] = useState<MetaPageType>("privacyPolicy");

  const quillRef = React.useRef<React.LegacyRef<ReactQuill>>(null);
  const [state, dispatch] = useReducer(amendPoliciesReducer, initialState);
  const { modules } = useQuillEditor();

  const [createPolicy, { isLoading: isCreatePolicyLoading }] =
    useCreatePolicyMutation();
  const [updatePolicy, { isLoading: isUpdatePolicyLoading }] =
    useUpdatePolicyMutation();
  const [deletePolicy, { isLoading: isDeletePolicyLoading }] =
    useDeletePolicyMutation();
  const { data: policy, refetch } = useGetPolicyQuery(params?.slug!, {
    skip: !params?.slug,
  });

  const btnDisabled = isCreatePolicyLoading || isUpdatePolicyLoading;

  const getBtnText = useMemo(() => {
    let btnText;
    if (isUpdatePolicyPage) {
      if (isCreatePolicyLoading || isUpdatePolicyLoading)
        btnText = "Updating Policy...";
      else btnText = "Update Policy";
    } else {
      if (isCreatePolicyLoading || isUpdatePolicyLoading)
        btnText = "Creating Policy...";
      else btnText = "Create Policy";
    }

    return btnText;
  }, [isCreatePolicyLoading, isUpdatePolicyLoading, isUpdatePolicyPage]);

  const handleImageUpload = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files![0];

      try {
        const imageUrl = await uploadImage(file);

        const quill = (
          quillRef as React.RefObject<ReactQuill>
        ).current?.getEditor();
        const range = quill?.getSelection();
        quill?.insertEmbed(range?.index!, "image", imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };
  };

  const handleLink = (value: any) => {
    const quill = (
      quillRef as React.RefObject<ReactQuill>
    ).current?.getEditor();
    if (value) {
      const href = prompt("Enter the URL");
      quill?.format("link", href);
    } else {
      quill?.format("link", false);
    }
  };

  const modulesWithHandlers = useMemo(() => {
    return {
      toolbar: {
        ...modules.toolbar,
        handlers: {
          image: handleImageUpload,
          link: handleLink,
        },
      },
    };
  }, [modules]);

  const setData = (state: string) => {
    setContent({ state, dispatch });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const policyData = {
        page,
        content: state.content,
        meta: state.meta,
      };

      if (isUpdatePolicyPage) {
        await updatePolicy([policyData, params?.slug]).unwrap();
      } else {
        await createPolicy(policyData).unwrap();
      }
      navigate(ROUTES.BLOGS);
    } catch (err) {
      console.log("Failed to create Policy:", err);
    }
  };

  const handleDelete = async () => {
    await deletePolicy(params?.slug);
    navigate(ROUTES.BLOGS);
  };

  useEffect(() => {
    if (params?.slug) refetch();
  }, [params?.slug]);

  useEffect(() => {
    setPage(policy?.page as MetaPageType);
    getPolicyData({
      state: {
        content: policy?.content ?? "",
        createdAt: policy?.createdAt ?? "",
        createdBy: policy?.createdBy ?? {
          email: "",
          username: "",
        },
        meta: policy?.meta ?? {
          description: "",
          title: "",
        },
      },
      dispatch,
    });
  }, [policy]);

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <h2 className="text-2xl font-semibold mb-4">Amend Policies</h2>
      <form onSubmit={handleSubmit}>
        {!isUpdatePolicyPage && (
          <div className="mb-4">
            <label htmlFor="pagetype">Page:</label>
            <div className="flex items-center">
              <div className="w-1/2">
                <label>
                  <input
                    className="mr-2"
                    name="pagetype"
                    type="radio"
                    value={"privacyPolicy"}
                    checked={page === "privacyPolicy"}
                    onChange={(e) => setPage(e.target.value as MetaPageType)}
                  />
                  Privacy Policy
                </label>
              </div>
              <div className="w-1/2">
                <label>
                  <input
                    className="mr-2"
                    name="pagetype"
                    value="tnc"
                    type="radio"
                    checked={page === "tnc"}
                    onChange={(e) => setPage(e.target.value as MetaPageType)}
                  />
                  Terms and Conditions
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="content"
          >
            Content*
          </label>
          <ReactQuill
            modules={modulesWithHandlers}
            value={state?.content}
            onChange={setData}
            className=" bg-white"
            ref={quillRef as any}
            placeholder="Content"
          />
        </div>
        <div className="border-b-2 border-gray-200 mb-4" />
        <h2 className="mb-4">
          Meta Info <i className="text-gray-500 text-sm">(optional)</i>
        </h2>
        <div className="mb-4">
          <input
            type="text"
            name="meta-title"
            value={state.meta.title}
            onChange={(e) =>
              setPoliciesMetaData({
                state: { key: "title", value: e.target.value },
                dispatch,
              })
            }
            className="w-full p-2 border rounded"
            placeholder="Meta title"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="meta-description"
            value={state.meta.description}
            onChange={(e) =>
              setPoliciesMetaData({
                state: { key: "description", value: e.target.value },
                dispatch,
              })
            }
            className="w-full p-2 border rounded"
            placeholder="Meta description"
          />
        </div>
        <button
          type="submit"
          className="py-2 mt-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
          disabled={btnDisabled}
        >
          {getBtnText}
        </button>
        {isUpdatePolicyPage && (
          <button
            type="button"
            className="ml-2 py-2 mt-2 px-4 bg-red-600 text-white rounded hover:bg-red-700"
            onClick={handleDelete}
            disabled={isDeletePolicyLoading}
          >
            {isDeletePolicyLoading ? "Deleting Policy..." : "Delete Policy"}
          </button>
        )}
      </form>
    </div>
  );
}

export default AmendPoliciesPage;

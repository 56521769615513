// src/components/Login.tsx
import React, { useEffect, useState } from "react";
import { useLoginUserMutation } from "../../features/api/apiSlice";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ROUTES } from "../../utils/constants";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginUser, { isError, error }] = useLoginUserMutation();
  const [cookies, setCookie] = useCookies(["sessionToken"]);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userData = await loginUser({ email, password }).unwrap();
      let now = new Date();
      let time = now.getTime();
      time += 3600 * 1000;
      now.setTime(time);
      // Save token and redirect
      setCookie("sessionToken", userData.token, {
        expires: new Date(new Date().getTime() + 3600 * 1000),
      });
      navigate(ROUTES.BLOGS);
      window.location.reload();
    } catch (err) {
      console.error("Failed to login:", err);
    }
  };

  useEffect(() => {
    if (cookies.sessionToken) navigate(ROUTES.BLOGS);
  }, []);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Login
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your password"
              required
            />
          </div>
          <div className="mb-6">
            {isError && (
              <p className="text-red-700 mt-1">
                {(error as any)?.data?.message}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Login
            </button>
            <Link
              to={ROUTES.RESET_PASSWORD}
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            >
              Reset Password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

// src/pages/CreateBlog.tsx
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useGetBlogByIdQuery,
} from "../../features/api/apiSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useQuillEditor from "../../features/hooks/useQuillEditor";
import {
  blogReducer,
  initialState,
  setTitle,
  setTags,
  setContent,
  setCoverImageUrl,
  setBlogData,
  setBlogMetaData,
  setFeatured,
} from "../../features/reducers/Blog/reducer";
import { uploadImage } from "../../utils/uploadImage";
import { ROUTES } from "../../utils/constants";

const Blog = ({ isUpdateBlogPage }: { isUpdateBlogPage?: boolean }) => {
  const quillRef = React.useRef<React.LegacyRef<ReactQuill>>(null);
  const params = useParams();
  const navigate = useNavigate();

  const [{ title, content, tags, coverImageUrl, meta, featured }, dispatch] =
    useReducer(blogReducer, initialState);
  const [coverImage, setCoverImage] = useState<File | null>(null);

  const [createBlog, { isLoading: isCreateBlogLoading }] =
    useCreateBlogMutation();
  const [updateBlog, { isLoading: isUpdateBlogLoading }] =
    useUpdateBlogMutation();
  const {
    data: blog,
    refetch,
    isUninitialized,
  } = useGetBlogByIdQuery(params?.slug!, {
    skip: !params?.slug,
  });

  const predefinedTags = useMemo(
    () => ["Technology", "Lifestyle", "Education", "Travel", "Food", "Health"],
    [],
  );

  const { modules } = useQuillEditor();

  const handleImageUpload = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files![0];

      try {
        const imageUrl = await uploadImage(file);

        const quill = (
          quillRef as React.RefObject<ReactQuill>
        ).current?.getEditor();
        const range = quill?.getSelection();
        quill?.insertEmbed(range?.index!, "image", imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };
  };

  const handleLink = (value: any) => {
    const quill = (
      quillRef as React.RefObject<ReactQuill>
    ).current?.getEditor();
    if (value) {
      const href = prompt("Enter the URL");
      quill?.format("link", href);
    } else {
      quill?.format("link", false);
    }
  };

  const modulesWithHandlers = useMemo(() => {
    return {
      toolbar: {
        ...modules.toolbar,
        handlers: {
          image: handleImageUpload,
          link: handleLink,
        },
      },
    };
  }, [modules]);

  const btnDisabled = isCreateBlogLoading || isUpdateBlogLoading;

  const getBtnText = useMemo(() => {
    let btnText;
    if (isUpdateBlogPage) {
      if (isCreateBlogLoading || isUpdateBlogLoading)
        btnText = "Updating Blog...";
      else btnText = "Update Blog";
    } else {
      if (isCreateBlogLoading || isUpdateBlogLoading)
        btnText = "Creating Blog...";
      else btnText = "Create Blog";
    }

    return btnText;
  }, [isCreateBlogLoading, isUpdateBlogLoading, isUpdateBlogPage]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let imageUrl = coverImageUrl;
      if (coverImage && !coverImageUrl) {
        imageUrl = await uploadImage(coverImage);
        setCoverImageUrl(imageUrl, dispatch);
      }

      const blogData = {
        title,
        content,
        tags,
        coverImage: imageUrl,
        featured,
        meta,
      };

      if (isUpdateBlogPage) {
        await updateBlog([blogData, params?.slug]).unwrap();
      } else {
        await createBlog(blogData).unwrap();
      }
      navigate(ROUTES.BLOGS);
    } catch (err) {
      console.error("Failed to create blog:", err);
    }
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTags = Array.from(
      e.target.selectedOptions,
      (option) => option.value,
    );
    setTags(selectedTags, dispatch);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setCoverImage(e.target.files[0]);
      let imageUrl = await uploadImage(e.target.files[0]);
      setCoverImageUrl(imageUrl, dispatch);
    }
  };

  useEffect(() => {
    setBlogData(
      {
        title: blog?.title ?? "",
        content: blog?.content ?? "",
        coverImageUrl: blog?.coverImage ?? "",
        tags: blog?.tags ?? [],
        featured: blog?.featured ?? false,
        meta: {
          title: blog?.meta.title,
          description: blog?.meta.description,
          keywords: blog?.meta.keywords.join(","),
        },
      },
      dispatch,
    );
  }, [blog]);

  useEffect(() => {
    if (!isUninitialized) refetch();
  }, []);

  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <h2 className="text-2xl font-semibold mb-4">Create Blog</h2>
      <form onSubmit={handleSubmit}>
        <h2 className="font-semibold mb-6">Blog Content Section</h2>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="title"
          >
            Title*
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value, dispatch)}
            placeholder="Title"
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="content"
          >
            Content*
          </label>
          <ReactQuill
            modules={modulesWithHandlers}
            value={content}
            onChange={(content: string) => setContent(content, dispatch)}
            className=" bg-white"
            ref={quillRef as any}
            placeholder="Content"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="tags"
          >
            Tags*
          </label>
          <select
            id="tags"
            multiple
            value={tags}
            onChange={handleTagChange}
            className="w-full p-2 border rounded"
          >
            {predefinedTags.map((tag) => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="coverImage"
          >
            Cover Image*
          </label>
          <input type="file" id="coverImage" onChange={handleImageChange} />
        </div>

        {coverImageUrl && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Preview:
            </label>
            <img src={coverImageUrl} />
          </div>
        )}

        <hr className="mb-4" />

        <h2 className="font-semibold mb-6">SEO Section</h2>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="meta-title"
          >
            Meta Title
          </label>
          <input
            type="text"
            id="meta-title"
            value={meta?.title}
            onChange={(e) => setBlogMetaData("title", e.target.value, dispatch)}
            className="w-full p-2 border rounded"
            placeholder="Meta title"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="description"
          >
            Meta Description
          </label>
          <input
            type="text"
            id="description"
            value={meta?.description}
            onChange={(e) =>
              setBlogMetaData("description", e.target.value, dispatch)
            }
            className="w-full p-2 border rounded"
            placeholder="Meta description"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="keywords"
          >
            Meta Keywords
          </label>
          <input
            type="text"
            id="keywords"
            value={meta?.keywords}
            onChange={(e) =>
              setBlogMetaData("keywords", e.target.value, dispatch)
            }
            className="w-full p-2 border rounded"
            placeholder="comma,seperated,keywords,without,spaces"
          />
        </div>

        <hr className="mb-4" />

        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="featured"
          >
            <input
              type="checkbox"
              id="featured"
              checked={featured}
              onChange={(e) => setFeatured(e.target.checked, dispatch)}
              className="p-2 border rounded mr-2"
              placeholder="comma,seperated,keywords,without,spaces"
            />
            Is Featured?
          </label>
        </div>

        <button
          type="submit"
          className="py-2 mt-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
          disabled={btnDisabled}
        >
          {getBtnText}
        </button>
      </form>
    </div>
  );
};

export default Blog;

import React, { useEffect, useReducer } from "react";
import {
  useGetAllBlogsQuery,
  useDeleteBlogMutation,
} from "../../features/api/apiSlice";
import {
  initialState,
  blogReducer,
  setBlogs,
  setSelected,
} from "../../features/reducers/Blogs/reducer";
import cls from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

const Blogs = () => {
  const { data: blogsData, isLoading, refetch } = useGetAllBlogsQuery();
  const [deleteBlog] = useDeleteBlogMutation();
  const [state, dispatch] = useReducer(blogReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    if (blogsData) {
      setBlogs(blogsData, dispatch);
    }
  }, [blogsData]);

  useEffect(() => {
    refetch();
  }, []);

  const handleSelectBlog = (slug: string) => {
    setSelected(
      { ...state.selected, [slug]: !Boolean(state.selected[slug]) },
      dispatch
    );
  };

  const handleSelectAll = () => {
    const allSelected = state.blogs.reduce(
      (acc, blog) => {
        acc[blog.slug] = true;
        return acc;
      },
      {} as { [slug: string]: boolean }
    );

    const isAllSelected =
      Object.keys(state.selected).length === state.blogs.length;
    setSelected(isAllSelected ? {} : allSelected, dispatch);
  };

  const handleUpdate = (slug: string) => {
    navigate(`${ROUTES.UPDATE_BLOG}/${slug}`);
  };

  const handleDeleteSelected = async () => {
    Object.entries(state.selected).forEach(async (entry) => {
      if (state.selected[entry[0]] && entry[1]) {
        await deleteBlog(entry[0]);
      }
    });
    setTimeout(() => {
      refetch();
    }, 500);
  };

  if (isLoading) return <div>Loading...</div>;

  if (!state.blogs.length)
    return (
      <div className={cls.CONTAINER}>
        <h1 className={cls.HEADER}>No Blogs</h1>
      </div>
    );

  return (
    <div className={cls.CONTAINER}>
      <h1 className={cls.HEADER}>Blogs</h1>
      <div className="flex justify-between mb-4">
        <button onClick={handleSelectAll} className={cls.SELECT_ALL_BUTTON}>
          {Object.entries(state.selected).filter((entry) => entry[1]).length ===
          state.blogs.length
            ? "Deselect All"
            : "Select All"}
        </button>
      </div>
      <ul className={cls.BLOG_LIST}>
        {state.blogs.map((blog) => (
          <li
            key={blog.slug}
            className={`${cls.BLOG_ITEM} ${state.selected[blog.slug] ? cls.SELECTED : ""}`}
            onClick={() => handleSelectBlog(blog.slug)}
          >
            <img
              src={blog.coverImage}
              alt={blog.title}
              className={cls.COVER_IMAGE}
            />
            <div className={cls.BLOG_DETAILS}>
              <h2 className={cls.TITLE}>{blog.title}</h2>
              <p className={cls.CREATED_BY}>By: {blog.createdBy.username}</p>
              <p className={cls.CREATED_AT}>
                Created at: {new Date(blog.createdAt).toLocaleDateString()}
              </p>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdate(blog.slug);
                }}
                className={cls.UPDATE_BUTTON}
              >
                Update
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button
        onClick={handleDeleteSelected}
        className={cls.DELETE_BUTTON}
        disabled={Object.keys(state.selected).length === 0}
      >
        Delete Selected
      </button>
    </div>
  );
};

export default Blogs;

import React, { useState } from "react";
import { useResetPasswordMutation } from "../../features/api/apiSlice";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [resetPassword, { isLoading, isSuccess, isError, error }] =
    useResetPasswordMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await resetPassword([{ email }]).unwrap();
    } catch (err) {
      console.error("Failed to reset password:", err);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        <h2 className="mb-6 text-2xl font-semibold text-center">
          Reset Password
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="block w-full px-3 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="block w-full px-4 py-2 font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-blue-400 focus:ring-offset-2"
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </button>

          {isSuccess && (
            <p className="mt-4 text-green-600">Password reset link sent!</p>
          )}
          {isError && (
            <p className="mt-4 text-red-600">
              Error:{" "}
              {(error as any)?.data?.message ?? "Failed to reset password"}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

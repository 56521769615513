export interface BlogState {
  title: string;
  content: string;
  tags: string[];
  coverImageUrl: string;
  featured: boolean;
  meta?: {
    title?: string;
    description?: string;
    keywords?: string;
  };
}

export type BlogActionType =
  | {
      type: "UPDATE_TITLE" | "UPDATE_CONTENT" | "UPDATE_COVER_IMAGE";
      payload: string;
    }
  | {
      type: "UPDATE_TAGS";
      payload: string[];
    }
  | {
      type: "UPDATE_BLOG_DATA";
      payload: BlogState;
    }
  | {
      type: "UPDATE_BLOG_METADATA";
      payload: { key: "title" | "description" | "keywords"; value: string };
    }
  | {
      type: "UPDATE_BLOG_FEATURED";
      payload: boolean;
    };

export const initialState: BlogState = {
  title: "",
  content: "",
  tags: [],
  coverImageUrl: "",
  featured: false,
  meta: {
    title: "",
    description: "",
    keywords: "",
  },
};

export function blogReducer(
  state: BlogState,
  action: BlogActionType,
): BlogState {
  switch (action.type) {
    case "UPDATE_TITLE":
      return { ...state, title: action.payload };
    case "UPDATE_CONTENT":
      return { ...state, content: action.payload };
    case "UPDATE_COVER_IMAGE":
      return { ...state, coverImageUrl: action.payload };
    case "UPDATE_TAGS":
      return { ...state, tags: action.payload };
    case "UPDATE_BLOG_DATA":
      return { ...state, ...action.payload };
    case "UPDATE_BLOG_FEATURED":
      return { ...state, featured: action.payload };
    case "UPDATE_BLOG_METADATA":
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return { ...state };
  }
}

export function setTitle(
  title: string,
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_TITLE", payload: title });
}

export function setContent(
  content: string,
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_CONTENT", payload: content });
}

export function setTags(
  tags: string[],
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_TAGS", payload: tags });
}

export function setCoverImageUrl(
  coverImageUrl: string,
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_COVER_IMAGE", payload: coverImageUrl });
}

export function setBlogData(
  blogData: BlogState,
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_BLOG_DATA", payload: blogData });
}

export function setFeatured(
  payload: boolean,
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_BLOG_FEATURED", payload });
}

export function setBlogMetaData(
  key: "title" | "description" | "keywords",
  value: string,
  dispatch: React.Dispatch<BlogActionType>,
) {
  dispatch({ type: "UPDATE_BLOG_METADATA", payload: { key, value } });
}

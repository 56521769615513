// src/features/api/apiSlice.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Cookies } from "react-cookie";

// Types for the Blog data
export interface Blog {
  _id: string;
  title: string;
  content: string;
  tags: string[];
  coverImage: string;
  slug: string;
  featured: boolean;
  meta: {
    title: string;
    description: string;
    keywords: string[];
  };
  createdBy: {
    username: string;
    email: string;
  };
  createdAt: string;
}

export interface Policy {
  _id: string;
  page: string;
  content: string;
  meta: {
    title: string;
    description: string;
  };
  createdBy: {
    username: string;
    email: string;
  };
  createdAt: string;
}

export interface User {
  username: string;
  email: string;
}

type ResetPasswordData = {
  email: string;
};

type ChangePasswordData = {
  password: string;
};

type TokenType = string;

type ResetPasswordRequest =
  | [ChangePasswordData, TokenType]
  | [ResetPasswordData];

interface ResetPasswordResponse {
  message: string;
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    validateSession: builder.query<User, void>({
      query: () => ({
        url: "/auth/validate-session",
        method: "GET",
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequest
    >({
      query: ([data, token]) => {
        let url = "/password-reset/reset";
        if (token) url += `/${token}`;
        return {
          url,
          method: "POST",
          body: data,
        };
      },
    }),
    createBlog: builder.mutation({
      query: (blogData) => ({
        url: "blogs/create",
        method: "POST",
        body: blogData,
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    updateBlog: builder.mutation({
      query: ([blogData, slug]) => ({
        url: `blogs/update/${slug}`,
        method: "PUT",
        body: blogData,
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    deleteBlog: builder.mutation({
      query: (slug) => ({
        url: `blogs/delete/${slug}`,
        method: "DELETE",
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    getAllBlogs: builder.query<Blog[], void>({
      query: () => "/blogs/all", // This is the API endpoint to get all blogs
    }),
    getBlogById: builder.query<Blog, string>({
      query: (id) => `/blogs/get/${id}`, // API endpoint to get a specific blog by ID
    }),
    createPolicy: builder.mutation({
      query: (policyData) => ({
        url: "policy/create",
        method: "POST",
        body: policyData,
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    updatePolicy: builder.mutation({
      query: ([policyData, slug]) => ({
        url: `policy/update/${slug}`,
        method: "PUT",
        body: policyData,
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    deletePolicy: builder.mutation({
      query: (slug) => ({
        url: `policy/delete/${slug}`,
        method: "DELETE",
        headers: {
          "x-auth-token": new Cookies().get("sessionToken") || "",
        },
      }),
    }),
    getPolicy: builder.query<Policy, string>({
      query: (id) => `/policy/get/${id}`, // API endpoint to get a specific policy by ID
    }),
  }),
});

export const {
  useValidateSessionQuery,
  useLoginUserMutation,
  useResetPasswordMutation,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useGetBlogByIdQuery,
  useCreatePolicyMutation,
  useUpdatePolicyMutation,
  useGetPolicyQuery,
  useDeletePolicyMutation,
} = apiSlice;

export const ROUTES = {
  BLOGS: "/",
  CREATE_BLOG: "/create",
  UPDATE_BLOG: "/update",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  AMEND_POLICIES: "/amend-policies",
  AMEND_PRIVACY_POLICY: "/amend-policies/privacyPolicy",
  AMEND_TNC: "/amend-policies/tnc",
};

type PolicyData = {
  content: string;
  createdAt: string;
  meta: {
    title: string;
    description: string;
  };
  createdBy: {
    username: string;
    email: string;
  };
};

export type MetaPageType = "privacyPolicy" | "tnc";

type MetaInfoPayload = {
  key: "title" | "description";
  value: string;
};

export type PoliciesActionType =
  | {
      type: "GET_POLICY_DATA";
      payload: PolicyData;
    }
  | {
      type: "SET_CONTENT";
      payload: string;
    }
  | {
      type: "SET_META_INFO";
      payload: MetaInfoPayload;
    };

export const initialState: PolicyData = {
  content: "",
  createdAt: "",
  createdBy: {
    username: "",
    email: "",
  },
  meta: {
    description: "",
    title: "",
  },
};

export function amendPoliciesReducer(
  state: PolicyData,
  action: PoliciesActionType,
): PolicyData {
  switch (action.type) {
    case "GET_POLICY_DATA":
      return { ...state, ...action.payload };
    case "SET_CONTENT":
      return {
        ...state,
        content: action.payload,
      };
    case "SET_META_INFO":
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return { ...state };
  }
}

export function getPolicyData({
  state,
  dispatch,
}: {
  state: PolicyData;
  dispatch: React.Dispatch<PoliciesActionType>;
}) {
  dispatch({
    type: "GET_POLICY_DATA",
    payload: state,
  });
}

export function setContent({
  state,
  dispatch,
}: {
  state: string;
  dispatch: React.Dispatch<PoliciesActionType>;
}) {
  dispatch({
    type: "SET_CONTENT",
    payload: state,
  });
}

export function setPoliciesMetaData({
  state,
  dispatch,
}: {
  state: MetaInfoPayload;
  dispatch: React.Dispatch<PoliciesActionType>;
}) {
  dispatch({
    type: "SET_META_INFO",
    payload: state,
  });
}

import { useMemo } from "react";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

const Image = Quill.import("formats/image");
Image.className = "img-fluid";
Quill.register(Image, true);

const Link = Quill.import("formats/link");

class CustomLink extends Link {
  static create(value: any) {
    const node = super.create(value);
    node.setAttribute("target", "_self");
    return node;
  }
}

Quill.register(CustomLink, true);

type UseQuillEditorReturnType = {
  modules: {
    toolbar: {
      container: (
        | string[]
        | {
            header: (number | boolean)[];
          }[]
        | (
            | {
                list: string;
                indent?: undefined;
              }
            | {
                indent: string;
                list?: undefined;
              }
          )[]
      )[];
    };
  };
};

function useQuillEditor() {
  const modules = useMemo(
    () => ({
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["link", "image"],
          ["clean"],
        ],
      },
    }),
    [],
  );

  return { modules } as UseQuillEditorReturnType;
}

export default useQuillEditor;

import { Blog } from "../../api/apiSlice";

export interface BlogsState {
  blogs: Blog[];
  selected: { [slug: string]: boolean };
}

export type BlogsActionType =
  | { type: "SET_BLOGS"; payload: Blog[] }
  | { type: "SET_SELECTED"; payload: { [slug: string]: boolean } };

export const initialState: BlogsState = {
  blogs: [],
  selected: {},
};

export function blogReducer(
  state: BlogsState,
  action: BlogsActionType
): BlogsState {
  switch (action.type) {
    case "SET_BLOGS":
      return { ...state, blogs: action.payload };
    case "SET_SELECTED":
      return { ...state, selected: action.payload };
    default:
      return { ...state };
  }
}

export function setBlogs(
  blogs: Blog[],
  dispatch: React.Dispatch<BlogsActionType>
) {
  dispatch({ type: "SET_BLOGS", payload: blogs });
}

export function setSelected(
  selected: { [slug: string]: boolean },
  dispatch: React.Dispatch<BlogsActionType>
) {
  dispatch({ type: "SET_SELECTED", payload: selected });
}

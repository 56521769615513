import React from "react";
import "./App.css";
import Header from "./Components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./Pages/Login";
import ResetPasswordPage from "./Pages/ResetPassword";
import { Provider } from "react-redux";
import { store } from "./store";
import { CookiesProvider } from "react-cookie";
import Blog from "./Pages/Blog";
import ChangePasswordPage from "./Pages/ChangePassword";
import Blogs from "./Pages/Blogs";
import { ROUTES } from "./utils/constants";
import AuthBoundary from "./Components/AuthBoundary";
import AmendPoliciesPage from "./Pages/AmendPolicies";

function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route
              path={ROUTES.BLOGS}
              element={
                <AuthBoundary protected>
                  <Blogs />
                </AuthBoundary>
              }
            />
            <Route
              path={ROUTES.CREATE_BLOG}
              element={
                <AuthBoundary protected>
                  <Blog key="create-blog" />
                </AuthBoundary>
              }
            />
            <Route
              path={ROUTES.LOGIN}
              element={
                <AuthBoundary>
                  <LoginPage />
                </AuthBoundary>
              }
            />
            <Route
              path={`${ROUTES.UPDATE_BLOG}/:slug`}
              element={
                <AuthBoundary protected>
                  <Blog key="update-blog" isUpdateBlogPage />
                </AuthBoundary>
              }
            />
            <Route
              path={ROUTES.RESET_PASSWORD}
              element={
                <AuthBoundary>
                  <ResetPasswordPage />
                </AuthBoundary>
              }
            />
            <Route
              path={`${ROUTES.CHANGE_PASSWORD}/:token`}
              element={
                <AuthBoundary>
                  <ChangePasswordPage />
                </AuthBoundary>
              }
            />
            <Route
              path={`${ROUTES.AMEND_POLICIES}`}
              key={"ap"}
              element={
                <AuthBoundary protected>
                  <AmendPoliciesPage />
                </AuthBoundary>
              }
            />
            <Route
              path={`${ROUTES.AMEND_POLICIES}/:slug`}
              key={"slg"}
              element={
                <AuthBoundary protected>
                  <AmendPoliciesPage isUpdatePolicyPage />
                </AuthBoundary>
              }
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  );
}

export default App;

export const uploadImage = async (image: File) => {
  const formData = new FormData();
  formData.append("image", image);

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/media/upload-image`,
    {
      method: "POST",
      body: formData,
      headers: {
        "x-auth-token": localStorage.getItem("token") || "",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Image upload failed");
  }

  const data = await response.json();
  return data.imageUrl;
};

import React from "react";
import { useValidateSessionQuery } from "../../features/api/apiSlice";
import { Link, NavLink } from "react-router-dom";
import { useCookies } from "react-cookie";
import logo from "../../assets/svg/logo.svg";
import cls from "./index.module.css";
import { ROUTES } from "../../utils/constants";

function Header() {
  const [, , deleteCookie] = useCookies(["sessionToken"]);
  const { data: user } = useValidateSessionQuery();

  const logoutUser = () => {
    deleteCookie("sessionToken");
    window.location.reload();
  };

  return (
    <header className={cls.HEADER}>
      <div className={cls.CONTAINER}>
        <div className={cls.LOGO}>
          <Link to={ROUTES.BLOGS} className={cls.LOGOLINK}>
            <img className={cls.LOGOIMAGE} src={logo} alt="Miyabee" />
          </Link>
        </div>
        <nav>
          <ul className={cls.NAVLIST}>
            {!user?.email && (
              <li>
                <NavLink to={ROUTES.LOGIN} className={cls.NAVLINK}>
                  {({ isActive }) => (
                    <span
                      className={isActive ? cls.NAVLINK_ACTIVE : cls.NAVLINK}
                    >
                      Login
                    </span>
                  )}
                </NavLink>
              </li>
            )}
            {user?.email && (
              <>
                <li>
                  <NavLink to={ROUTES.BLOGS} className={cls.NAVLINK}>
                    {({ isActive }) => (
                      <span
                        className={isActive ? cls.NAVLINK_ACTIVE : cls.NAVLINK}
                      >
                        Blogs
                      </span>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ROUTES.CREATE_BLOG} className={cls.NAVLINK}>
                    {({ isActive }) => (
                      <span
                        className={isActive ? cls.NAVLINK_ACTIVE : cls.NAVLINK}
                      >
                        Create Blog
                      </span>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={ROUTES.AMEND_PRIVACY_POLICY}
                    className={cls.NAVLINK}
                  >
                    {({ isActive }) => (
                      <span
                        className={isActive ? cls.NAVLINK_ACTIVE : cls.NAVLINK}
                      >
                        Privacy Policy
                      </span>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ROUTES.AMEND_TNC} className={cls.NAVLINK}>
                    {({ isActive }) => (
                      <span
                        className={isActive ? cls.NAVLINK_ACTIVE : cls.NAVLINK}
                      >
                        T&C
                      </span>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={ROUTES.LOGIN}
                    className={cls.NAVLINK}
                    onClick={logoutUser}
                  >
                    {({ isActive }) => (
                      <span
                        className={isActive ? cls.NAVLINK_ACTIVE : cls.NAVLINK}
                      >
                        Logout
                      </span>
                    )}
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../features/api/apiSlice";
import { validateToken } from "../../utils/validateToken";
import { ROUTES } from "../../utils/constants";

function ChangePasswordPage() {
  const [password, setPassword] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const [resetPassword, { isSuccess, error, isError, isLoading }] =
    useResetPasswordMutation();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      await resetPassword([{ password }, params?.token!]);
    } catch (err) {
      console.log("Failed to reset password: ", err);
    }
  };

  const initialPageLoadFunc = async () => {
    const isValid = await validateToken(params?.token!);
    if (!isValid) navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (!params?.token) navigate(ROUTES.LOGIN);
    else {
      initialPageLoadFunc();
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) navigate(ROUTES.LOGIN);
    }
  });

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Login
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              New Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your password"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {isLoading ? "Loading" : "Change Password"}
            </button>
          </div>
          {isSuccess && (
            <p className="mt-4 text-green-600">Password reset link sent!</p>
          )}
          {isError && (
            <p className="mt-4 text-red-600">
              Error:{" "}
              {(error as any)?.data?.message ?? "Failed to reset password"}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordPage;

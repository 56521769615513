import React, { useEffect } from "react";
import { useValidateSessionQuery } from "../../features/api/apiSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/constants";
import cls from "./index.module.css";

interface AuthBoundaryProps {
  protected?: boolean;
  children: React.ReactNode;
}

const AuthBoundary: React.FC<AuthBoundaryProps> = ({
  protected: isProtected,
  children,
}) => {
  const { data: user, isLoading } = useValidateSessionQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isProtected && !user?.email && !isLoading) {
      // Redirect to login page if protected and user is not authenticated
      navigate(ROUTES.LOGIN);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className={cls.loader} />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthBoundary;

export async function validateToken(token: string) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/password-reset/reset/${token}/validate`,
    {
      method: "GET",
    }
  );

  const data = await response.json();
  return data.isValid;
}
